/* BRAND COLORS ====================================== */
$blue			: 	#008DA8;
$yellow			: 	#FBE796;
$green			: 	#1BAA8F;
$orange 		: 	#F47524;
$coral 			: 	#F26F60;
$sand 			: 	#FAFBE6;

/* WAVE 1 = BACK, WAVE 2 = MID, WAVE 3 = FRONT */
$blue_wave_1	: 	#0084A0;
$blue_wave_2 	: 	#007895;
$blue_wave_3 	: 	#006D8B;

$orange_wave_1	: 	#F06B1F;
$orange_wave_2 	: 	#EF601A;
$orange_wave_3 	: 	#EE5616;

$green_wave_1	: 	#17A286;
$green_wave_2 	: 	#13977A;
$green_wave_3 	: 	#108D6F;

$coral_wave_1	: 	#F06657;
$coral_wave_2 	: 	#EE5A4C;
$coral_wave_3 	: 	#EC5043;

/* BASE COLORS  ====================================== */
$black          :   #010101;
$white          :   #FEFEFE;
$placeholder	: 	#757575;
$lt_gray		: 	#E3E5E4;
$gray 			: 	#ECEDED;
$dk_gray		: 	#45504B;


/* TYPOGRAPHY   ====================================== */
$font_primary   : 	'Raleway', sans-serif;