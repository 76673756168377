@import "reset";
@import "variables";
@import "mixins";

/* GLOBAL STYLES ====================================== */
body {
    background-color: $blue;
    height: auto;

    * {
        // transition: all 0.25s ease-in-out;
    }

    // @media (prefers-color-scheme: dark) {
    //     background-color: $black;

    //     p,
    //     li,
    //     button,
    //     .testimonial__quote,
    //     .testimonial__title {
    //         color: $white;
    //     }

    //     picture,
    //     img,
    //     video {
    //         opacity: 0.8;
    //     }

    //     .header {
    //         background-color: $black;

    //         .nav__item {
    //             color: $white;
    //         }

    //         .header__logo {
    //             svg {
    //                 path {
    //                     fill: $white;
    //                 }
    //             }
    //         }
    //     }

    //     .form-container {
    //         input[type="text"],
    //         input[type="email"],
    //         input[type="tel"],
    //         textarea {
    //             background-color: rgba($white, 0.9);
    //         }
    //     }

    //     .pod {
    //         .pod__section {
    //             .pod__copy {
    //                 color: $white;
    //             }
    //         }
    //     }
    // }
}

#root {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    transition: background-color 1.5s ease-in-out 0.25s;
}

section {
    width: 100vw;
    margin: 0 auto;

    &::not(.picker),
    &::not(.form-container) {
        max-width: 1480px;
    }

    &.picker {
        max-width: 1024px;
    }
}

h1, h2, h3, h4 {
	font-family: $font_primary;
    color: $white;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.1rem;
}

p, ul, ol, li {
    font-family: $font_primary;
}

a {
    cursor: pointer;
    text-decoration: none;
}

button {
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

textarea {
    resize: none;
}

.hidden {
    &.visually {
        position: absolute;
        left: 100vw;
    }
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.copy_link {
    color: $white;
    font-weight: 700;
    text-decoration: none;
}

.pod__cta {
    letter-spacing: 0.1rem;
}
